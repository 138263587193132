<template>
  <label
    class="counter"
    :class="{
      'counter--active': hasHover || hasFocus,
      'counter--disabled': disableInputEdit,
    }"
    @mouseover="mouseOverHandler"
    @mouseleave="mouseOutHandler"
    ref="wrapper"
  >
    <template
      v-if="disableInputEdit"
    >
      <span class="counter__input counter__input--disabled">{{ value }}</span>
    </template>
    <template
      v-else
    >
      <input
        :type="type"
        inputmode="numeric"
        class="counter__input"
        :name="name"
        v-model="value"
        @keyup="keyUpHandler"
        @focus="focusHandler"
        @blur="blurHandler"
        :disabled="disableInputEdit"
        :min="counter.minQuantity"
        :max="counter.maxQuantity"
        ref="input"
        autocomplete="off"
        autocorrect="off"
        tabindex="-1"
      >
      <button
        aria-label="increase"
        class="counter__button counter__button--increase"
        :class="{
          'counter__button--disabled': this.value === this.counter.maxQuantity
        }"
        @click.prevent="increase"
      />
      <button
        aria-label="decrease"
        class="counter__button counter__button--decrease"
        :class="{
          'counter__button--disabled': this.value === this.counter.minQuantity
        }"
        @click.prevent="decrease"
      />
    </template>
  </label>
</template>

<script lang="ts">
  import {Vue, Options, Prop, Watch} from 'vue-property-decorator';
  import type Counter from '../../types/Counter';

  @Options({
    name: 'counter-input',
    emits: ['update']
  })
  export default class CounterInput extends Vue {
    @Prop({required: true})
    counter!: Counter;
    @Prop({default: false})
    onlyOneItem!: boolean;
    @Prop({default: 'quantity'})
    name: string;
    @Prop({type: Boolean, default: false})
    disableInputEdit: boolean;

    value: number|string = !this.onlyOneItem ? this.counter.preselectedQuantity : 1;
    hasFocus: boolean = false;
    hasHover: boolean = false;
    isTouch: boolean = null;
    type: 'number'|'text' = null;

    focusValue: number|string;

    get numericValue() {
      return Number(this.value);
    }

    @Watch('counter.preselectedQuantity')
    onCounterChange() {
      this.value = this.counter.preselectedQuantity;
    }

    created() {
      this.isTouch = 'ontouchstart' in window;
      this.type = this.isTouch ? 'number' : 'text';
    }

    increase() {
      let newQuantity = this.numericValue + 1;

      if (newQuantity <= this.counter.maxQuantity) {
        this.value = newQuantity;
        this.$emit('update', this.numericValue);
      }
    }

    decrease() {
      let newQuantity = this.numericValue - 1;

      if (newQuantity >= this.counter.minQuantity) {
        this.value = newQuantity;
        this.$emit('update', this.numericValue);
      }
    }

    keyUpHandler(event) {
      if (event.key === 'Enter' || event.key === 13) {
        event.preventDefault();
        this.hasHover = false;
        this.hasFocus = false;
        (<HTMLElement> this.$refs.input).blur();
      }
    }

    blurHandler() {
      const isWrapperChildActive = (<HTMLElement> this.$refs.wrapper).querySelector(':focus, :hover');
      this.hasFocus = false;

      if (!isWrapperChildActive) {
        this.hasHover = false;
        if (this.value === '') {
          this.value = this.focusValue && Number(this.focusValue) >= this.counter.minQuantity ? this.focusValue : this.counter.minQuantity;
        } else {
          if (typeof this.value === 'string') {
            this.value = this.value.replace(/[^0-9]/g, '');
          }
        }
      }

      this.value = Number(this.value);
      this.value = Math.max(this.value, this.counter.minQuantity);
      this.value = Math.min(this.value, this.counter.maxQuantity);
      this.$emit('update', this.numericValue);
    }

    focusHandler() {
      this.hasFocus = true;
      this.focusValue = this.value;
      this.value = '';
    }

    mouseOverHandler() {
      this.hasHover = true;
    }

    mouseOutHandler() {
      this.hasHover = false;
    }

    setValue(value: number) {
      this.value = value;
    }
  }

</script>
